import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <Seo title="Our Services for Adults" />

    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1>Our Services for Adults</h1>
      </Container>
    </div>

    <div className="container singleCol">
    <p className="text-center">
          <StaticImage
            src="../../images/adults-around-a-board-room-table.jpg"
            loading="eager"
            height={375}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Adults around a table"
          />  
          </p>
          {/* <p className="lead">
          We can help you feel more comfortable with communicating at work, including improving communication with colleagues, learning or refining public speaking skills, and how to harness and actualize your language skills to improve work performance. Our expertise with articulation and language will help you build the necessary skills to take your communication to the next level. 
          </p> */}

          <p className="lead">
            We will help you recover and build your speech and language skills when you have experienced a stroke or traumatic brain injury, so that you can get back to your daily routine, work, travel, and enjoy all aspects of your life. With our guidance, you will learn skills that will take your communication to the next level; helping you build your interpersonal skills and confidence in the workplace, learning and refining public speaking skills, and harnessing and actualizing your language skills to improve work performance. 
          </p>
    </div>
    
    <Container>
      <Row className="g-lg-5">

      
        
        <Col lg={7}>
          <p className="text-center">
            {/* <StaticImage
              src="../../images/progress-pride-flag.jpg"
              loading="eager"
              height={375}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Progress pride flag"
            />   */}
          </p>
          <h2>Voice Therapy</h2>
          <p>
          Voice therapy sessions are available for the following conditions:
          </p>
          <ul>
            <li>Misuse or abuse of the voice (e.g., chronic screaming or shouting)</li>
            <li>Medical conditions (e.g., laryngitis, vocal cord lesions, or paralysis)</li>
            <li>Neurological disorders (e.g., Parkinson’s disease, stroke, or cerebral palsy)</li>
            <li>Voice habits (e.g., poor breathing, inadequate vocal cord closure, or excessive tension)</li>
          </ul>
          <h3>Goals of Voice Therapy</h3>
          <p>The primary goals of voice therapy are to:</p>
          <ul>
            <li>Improve vocal quality and loudness</li>
            <li>Enhance vocal endurance and sustainability</li>
            <li>Reduce vocal strain and fatigue</li>
            <li>Increase vocal flexibility and range</li>
            <li>Promote healthy vocal habits and vocal hygiene practices</li>
          </ul>
          
          <h3>Assessment and Treatment</h3>
          <p>A comprehensive assessment is done to identify the underlying causes of a voice disorder and develop a personalized treatment plan. The assessment may include:</p>
          <ul>
            <li>Clinical evaluation of vocal function and quality</li>
            <li>Acoustic analysis of voice characteristics (e.g., pitch, loudness, and quality)</li>
            <li>Patient history and self-report of symptoms and vocal habits</li>
          </ul>
          
          <h3>Treatment strategies may include</h3>
          <ul>
            <li>Exercises to strengthen and relax the vocal cords and surrounding muscles</li>
            <li>Techniques to improve breathing and respiration</li>
            <li>Vocal hygiene practices (e.g., hydration, avoidance of irritants)</li>
            <li>Modification of vocal habits and behaviors</li>
            <li>Biofeedback and auditory feedback to enhance vocal production</li>
          </ul>

          <h3>Specialized Techniques</h3>
          <ul>
            <li>Various specialized techniques in voice therapy may be used, such as:</li>
            <li>Physiologic voice therapy focuses on balancing the three systems of voice production </li>
            <li>(respiration, phonation, and resonance)</li>
            <li>Symptomatic voice therapy: targets specific symptoms (e.g., hoarseness, breathiness, or vocal fatigue)</li>
            <li>Vocal function exercises: designed to improve vocal cord closure, vocal fold vibration, and resonance</li>
          </ul>
        </Col>

        <Col lg={5}>
          
          
          

          <p className="text-center">
          <StaticImage
            src="../../images/accent-mod-group.jpg"
            width={600}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="3 people sitting around a cafe table"
          />  
          </p>
          <h2>Accent Modification</h2>
          <p>
            When we learn another language, we try to perfect speech patterns, intonations, and non-verbal communication specific to that language, but we are often limited in reaching our goal of fluency. Accents sometimes make it difficult to communicate with other people, and can make work and other activities more challenging. 
          </p>
          <p>
            If you are seeking accent modification, you may be looking to have more successful business and personal relationships. People choose to modify their accent for public speaking, acting, giving clear and engaging presentations, improving business management skills and professional communication, or demonstrating improved leadership skills.   
          </p>
        </Col>
        

        
        
      </Row>
    </Container>
    
  </Layout>
)

export default IndexPage
